<template>
  <div class="container-xl bg-grey">
    <div class="views">
      <section class="space-content"></section>

      <b-row class="position-relative">
        <b-col>
          <!-- Heightened Text -->
          <div
            class="medium-text fade-in-up-element overflow-hidden freight-big-pro-book"
          >
            A Heightened Perspective of Breathtaking
          </div>

          <!-- Views Text -->
          <div
            class="views-text overflow-hidden fade-in-up-element freight-big-pro-book xl-text text-uppercase zIndex-21"
          >
            Views
          </div>

          <!-- Video & Sub Text -->

          <div class="video-views-container fade-in-up-video w-100 h-100">
            <video
              autoplay
              muted
              loop
              class="embed-responsive-item"
              allowfullscreen
              alt="Ariel drone shot of city scape."
              playsinline
            >
              <source src="/videos/360_Pan_loop.mp4" type="video/mp4" />
              Sorry, your browser doesn't support embedded videos.
            </video>
            <div
              class="small-text views-sub-text fade-in-up-element lato-regular"
            >
              Towering over the renowned neighbourhoods of Forest Hill, South
              Hill, and Summerhill, the views of this pristine condominium
              community are truly marvelous. Perched above them all on a hill of
              it's own, it offers endless sight lines from a panoramic
              perspective. A celebrated feature of this illustrious lifestyle,
              exclusively available at The Hill.
              <br />
              <br />
              The extraordinary lifestyle extends beyond the surrounding hills;
              into the canopied valleys, all the way to the Toronto skyline with
              the magnificent lures of a cityscape’s verdant oasis by day. Above
              the affluent hills, at night, city lights sparkle through the dark
              canopied landscape, creating a visual symphony of splendour.
              Street lights like millions of shinning stars rise in harmony to
              traffic lights as an ode to The Hill.
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
export default {
  data() {
    return {};
  },
  components: {},
  mounted() {
    gsap.registerPlugin(ScrollTrigger);
    ScrollTrigger.refresh();
    setTimeout(() => {
      this.videoReveal();
    }, 500);
  },
  methods: {
    videoReveal() {
      let fadeInUpVideo = document.querySelectorAll(".fade-in-up-video");

      fadeInUpVideo.forEach((container) => {
        let fadeInUpVideo_live = this.fadeInUpVideo;
        fadeInUpVideo_live = gsap.timeline({
          scrollTrigger: {
            trigger: ".views-text",
            start: "top 58%",
            stagger: 0.2,
          },
        });

        fadeInUpVideo_live.set(container, { autoAlpha: 1, zIndex: 1 });
        fadeInUpVideo_live.from(container, 1, {
          opacity: 0,
          y: 100,
          ease: "power2.out",
        });
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/assets/styles/variables";
.views {
  position: relative;
  padding: 0px 0px 450px 100px;
  .space-content {
    padding-top: 50px;
  }
  .video-views-container {
    max-height: 450px;
    position: absolute;
    top: 10rem;
    padding-left: 14rem;
    padding-right: 4rem;
    z-index: 0;
    video {
      max-width: 850px;
      max-height: 400px;
      width: 100%;
      z-index: 0;
    }
  }
  .xl-text {
    z-index: 10000;
    position: relative;
    height: 130px;
    margin-top: 20px;
  }
  .medium-text {
    font-size: 2rem;
  }
  .small-text {
    max-width: 800px;
    font-size: 0.8rem;
    padding-top: 10px;
  }
  @media screen and (max-width: 950px) {
    .xl-text {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 770px) {
    padding: 0px 45px 100px 45px;
    .small-text {
      position: unset;
      padding-right: 0px;
    }
    .video-views-container {
      position: unset;
      padding-left: unset;
      padding-top: 1.2rem;
      padding-right: 0px;
    }
    .xl-text {
      margin-top: 10px;
    }
  }
  @media screen and (max-width: 500px) {
    padding: 0px 10px 40px 10px;
    .space-content {
      padding-top: 40px;
    }
    .medium-text {
      font-size: 1.6rem;
      line-height: 2rem;
    }
    .xl-text {
      margin-top: 0px;
      max-height: 80px;
    }
    .small-text {
      font-size: 12px;
    }
  }
}
</style>
